
import { storyblokEditable } from "@storyblok/react";

import Button from "./Button";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = ({ blok }) => {

  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  return <div className="mb-[127px]"><nav 
             className="absolute top-0 left-0 w-screen h-[127px] p-[30px] bg-white flex flex-row items-center justify-between"
             {...storyblokEditable(blok)} >
            <img className="w-[120px]" src="https://mrstudios.eu/wp-content/uploads/2022/07/MRstudios-logo.png" />
            { isAuthenticated ? (<div className="flex flex-row align-center">
              <img className="w-[50px] h-[50px] rounded-full" src={user.picture}/>
              <div className="text-left ml-3">
                <div>{`${user.given_name} ${user.family_name}`}</div>
                <div 
                  onClick={(() => { logout()})}
                  className="underline cursor-pointer mt-[-5px]"
                >Log out</div>
              </div>
            </div>) : (<div className="bttn" onClick={() => loginWithRedirect()}> Log in </div>) }
           
        </nav></div>;
};
 
export default Navbar;