import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useAuth0 } from "@auth0/auth0-react"; 
import Navbar from './Navbar'

const Page = ({ blok }) => {
  
  const {  isAuthenticated } = useAuth0();

  return isAuthenticated ? (<main {...storyblokEditable(blok)}>
  {blok.body
    ? blok.body.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))
    : null}
</main>) : 
(<main><Navbar /><p className="mt-[150px]">Looks like you are not logged in yet.</p></main>) 
  
};
 
export default Page;