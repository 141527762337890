import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";

import { Auth0Provider } from "@auth0/auth0-react";

import { storyblokInit, apiPlugin } from "@storyblok/react";

import Page from './components/Page'
import Teaser from './components/Teaser'
import Grid from './components/Grid'
import Feature from './components/Feature'
import Navbar from './components/Navbar'
import AppThumbnail from './components/AppThumbnail'
import Headline from './components/Headline'
import InstructionItem from './components/InstructionItem'
import InstructionContainer from './components/InstructionContainer'
import RenderingCard from './components/RenderingCard';


import {
  createBrowserRouter,
  RouterProvider, Route, createRoutesFromElements,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="home" element={<App />} />
    </Route>
  )
);

storyblokInit({
  accessToken: "fLyuQogOE61koj7b5Lv5Rgtt",
  use: [apiPlugin],
  components: {
    page: Page,
    teaser: Teaser,
    grid: Grid,
    feature: Feature,
    navbar: Navbar,
    appthumbnail: AppThumbnail,
    headline: Headline,
    instructionitem: InstructionItem,
    instructioncontainer: InstructionContainer,
    renderingcard: RenderingCard,
  }
});


ReactDOM.render(
    <Auth0Provider
           domain="dev-72us9oib.us.auth0.com"
           clientId="mx6hzXKvADdsvSz21UbgUDQ9M2bAhVg2"
          redirectUri={window.location.origin}
          >
            <Helmet link={[
              {"rel": "icon", 
               "type": "image/png", 
               "href": "https://mrstudios.eu/wp-content/uploads/2022/07/cropped-mrfavicon-192x192.png"
              }
             ]}>
                <meta charSet="utf-8" />
                <title>MRstudios 3D portal</title>
            </Helmet>
            <React.StrictMode>
              <RouterProvider router={router} />
            </React.StrictMode>
    </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
